a {
  &:focus {
    outline: none;
  }
}

a:not([href]) {
  color: inherit;
  text-decoration: none;

  &:hover {
    color: inherit;
    text-decoration: none;
  }
}

ol, ul {
  padding-left: 0;
  margin-bottom: 0;
}

p {
  margin-top: 0;
  margin-bottom: 0;
}
